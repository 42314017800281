import { Organization } from "./organization";

export class Education {
  
    title;
    center;
    location;
    level;
    from;
    to;
    description;
  
    constructor(json) {
      json.center = new Organization(json.center);
      Object.assign(this, json)
    }

    get timespan() {
      if (this.from === this.to) {
        return this.from?.toString() ?? '';
      }    
  
      return `${this.from} - ${this.to}`;
    }
  
    toString() {
      return `${this.title} - ${this.center.name} (${this.level}) - ${this.location};`
       + ` ${this.timespan}: ${this.description}`;
    }

    getHtmlComponent() {
      return <span>
        {this.title} - <a href={this.center.url}>{this.center.name}</a> ({this.level}) - {this.location}; {this.timespan}: {this.description}
      </span>
    }
  }