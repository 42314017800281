import { Separator } from "./separator";

export function ResumeComponent(props) {

  const resume = props.resume;

  return <>

    <div id="profileBasicInfo">
      <img src="profile.png"/>
      <h1>{resume.name}</h1>
    </div>

    <Separator/>

    <div>

      <p>{resume.profile}</p>

      <Separator/>

      <div>
        <p><b>Experience</b></p>
        {resume.experience?.map((e, i) => <p key={`experience-${i}`}>- {e.getHtmlComponent()}</p>)}
        
      </div>

      <br/>

      <div>
        <p><b>Education</b></p>
        {resume.education?.map((e, i) => <p key={`education-${i}`}>- {e.getHtmlComponent()}</p>)}
      </div>

      <br/>

      <div>
        <p><b>Skills</b></p>
        <p>{resume.getSkills()}.</p>
      </div>

      <br/>

      <div>
        <p><b>Personality</b></p>
        <p>{resume.personality}</p>
      </div>

      <br/>

      <div>
        <p><b>Languages</b></p>
        <p>{resume.getLanguages()}.</p>
      </div>

      <Separator/>

      <div>
        <p>Email address: <a href={`mailto:${resume.emailAddress}`}>{resume.emailAddress}</a></p>
        <p>Location: {resume.location}</p>
        <p>Birth date: {resume.birthDate?.toLocaleDateString('en-gb').replace(/\//g, "-")}</p>
        
      </div>
    </div>
  </>;
}