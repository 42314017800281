import { Education } from "./education";
import { Experience } from "./experience";
import { Page, Text, View, Document, StyleSheet, Link, Image } from '@react-pdf/renderer';

export class Resume {

    name;
    profile;
    experience;
    education;
    skills;
    personality;
    languages;
    emailAddress;
    location;
    birthDate;

    constructor(json) {
        json.experience = json.experience?.map(e => new Experience(e));
        json.education = json.education?.map(e => new Education(e));
        json.birthDate = new Date(json.birthDate);
        Object.assign(this, json);
    }

    joinList(list) {

        if (!list) {
            return '';
        }

        return `${list.slice(0, -1).join(', ')} and ${list.slice(-1)}`;
    }

    getSkills() {
        return this.joinList(this.skills);
    }

    getLanguages() {
        return this.joinList(this.languages);
    }

    getDocument() {

        const styles = StyleSheet.create({
            page: {
                padding: 50,
                flexDirection: 'column',
                fontFamily: 'Helvetica',
                fontSize:'12px'
            },
            section: {
                margin: 10,
            },
            title: {
                fontFamily: 'Helvetica-Bold',
            },
            listTitle: {
                fontFamily: 'Helvetica-Bold',
                marginBottom: 10
            },
            listContent: {
                marginBottom: 10
            }
        });

        return <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image src="profile.png" style={{ width: "75px", marginBottom: "20px" }}></Image>
                    <Text style={styles.title}>{this.name}</Text>
                </View>
                    
                <View style={styles.section}>
                    <Text>{this.profile}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.listTitle}>Experience</Text>
                    {this.experience?.map((e, i) => <Text key={`experience-${i}`} 
                        style={styles.listContent}>- {e.toString()}</Text>)}
                </View>

                <View style={styles.section}>
                    <Text style={styles.listTitle}>Education</Text>
                    {this.education?.map((e, i) => <Text key={`education-${i}`} 
                        style={styles.listContent}>- {e.toString()}</Text>)}
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Skills</Text>
                    <Text>{this.getSkills()}.</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Personality</Text>
                    <Text>{this.personality}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Languages</Text>
                    <Text>{this.getLanguages()}.</Text>
                </View>

                <View style={styles.section}>
                    <Text>Email address: <Link style={{textDecoration: "none", color: "black"}} src={`mailto:${this.emailAddress}`}>{this.emailAddress}</Link></Text>
                    <Text>Location: {this.location}</Text>
                    <Text>Birth date: {this.birthDate?.toLocaleDateString('en-gb').replace(/\//g, "-")}</Text>
                </View>
            </Page>
        </Document>
    }
  }