import './App.css';

import { Resume } from './model/resume';
import { ResumeDownload } from './resume-download';
import { ResumeComponent } from './resume-component';
import { Separator } from './separator';
import { davidpaarup } from './data';

function App() {
  const resume = new Resume(davidpaarup);
  
  return (
    <>
      <ResumeComponent resume={resume}/>
      <Separator/>
      <ResumeDownload document={resume.getDocument()} name={resume.name}></ResumeDownload>
    </>
  );
}

export default App;
