import { Organization } from "./organization";

export class Experience {
  
    from;
    to;
    location;
    company;
    position;
    type;
    description;
    current;
  
    constructor(json) {
      json.company = new Organization(json.company);
      Object.assign(this, json);
    }

    get timespan() {
      if (this.from === this.to) {
        return this.from?.toString() ?? '';
      }    
  
      return `${this.from} - ${this.current ? 'now' : this.to}`;
    }

    toString() {
      return `${this.position} - ${this.company.name} ${this.type !== "Full-time" ? 
        '(' + this.type + ') ': ''}- ${this.location};`
       + ` ${this.timespan}: ${this.description}`;
    }

    getHtmlComponent() {
      return <span>
        {this.position} - <a href={this.company.url}>{this.company.name}</a> {this.type !== "Full-time" ? '(' + this.type + ') ' : ''}- {this.location}; {this.timespan}: {this.description}
      </span>
    }
  }
