import { PDFDownloadLink } from "@react-pdf/renderer";
import React from 'react'

export function ResumeDownload(props) {

  const filename = `${props.name.toLowerCase().replace(' ', '')}_resume.pdf`;

  const document = props.document;

  return <a>
        <PDFDownloadLink document={document} fileName={filename}>
          Download resume
        </PDFDownloadLink>
      </a>;
}